var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row maindashboard" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("div", { staticClass: "col-12 no-padding row" }, [
              _vm.isUninjury
                ? _c("div", { staticClass: "col-5" }, [
                    _c("div", { staticClass: "cardcontents" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _vm._v(" 무재해 현황 "),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "card-body",
                          staticStyle: { height: "213px" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("apexchart", {
                                ref: "uninjuryChart",
                                attrs: {
                                  height: "220px",
                                  type: "radialBar",
                                  width: _vm.uninjuryChart.chartWidth,
                                  options: _vm.uninjuryChart.chartOptions,
                                  series: _vm.uninjuryChart.series,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                staticStyle: {
                                  "text-align": "center !important",
                                  "font-weight": "600",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.uninjuryData.startDt) +
                                    " ~ " +
                                    _vm._s(_vm.uninjuryData.targetExpectedDt) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                staticStyle: {
                                  "text-align": "center !important",
                                },
                              },
                              [
                                _c(
                                  "q-chip",
                                  {
                                    attrs: {
                                      outline: "",
                                      square: "",
                                      color: "primary",
                                      "text-color": "white",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.uninjuryData.deptName) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { class: _vm.isUninjury ? "col-7" : "col-12" }, [
                _c("div", { staticClass: "cardcontents" }, [
                  _c("div", { staticClass: "card-header" }, [
                    _vm._v(" 안전준비태세(SAFCON) 경보단계 "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "card-body",
                      staticStyle: { height: "213px" },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-4 safcon-left",
                            staticStyle: { "text-align": "center !important" },
                          },
                          [
                            _c("img", {
                              class:
                                _vm.safconText != "무사고" ? "safconimgOn" : "",
                              attrs: { src: _vm.safconImg, width: "90px" },
                              on: { click: _vm.clickSafcon },
                            }),
                            _c("br"),
                            _c("div", { class: _vm.safconTextClass }, [
                              _vm._v(_vm._s(_vm.safconText)),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-8 safcon-right",
                            staticStyle: { "text-align": "center !important" },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-3",
                                  staticStyle: {
                                    "text-align": "center !important",
                                  },
                                },
                                [
                                  _c("div", { class: _vm.safconTextClass1 }, [
                                    _vm._v("무사고"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-3",
                                  staticStyle: {
                                    "text-align": "center !important",
                                  },
                                },
                                [
                                  _c("div", { class: _vm.safconTextClass2 }, [
                                    _vm._v("주의"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-3",
                                  staticStyle: {
                                    "text-align": "center !important",
                                  },
                                },
                                [
                                  _c("div", { class: _vm.safconTextClass3 }, [
                                    _vm._v("경계"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-3",
                                  staticStyle: {
                                    "text-align": "center !important",
                                  },
                                },
                                [
                                  _c("div", { class: _vm.safconTextClass4 }, [
                                    _vm._v("심각"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-12 safconDate",
                                  staticStyle: {
                                    "text-align": "center !important",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.safconData.startDt) +
                                      " ~ " +
                                      _vm._s(_vm.safconData.endDt) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-12 safconTeam",
                                  staticStyle: {
                                    "text-align": "center !important",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _vm._v("안전사고 "),
                                      _c("span", { staticClass: "safconbox" }, [
                                        _vm._v(_vm._s(_vm.safconData.total)),
                                      ]),
                                      _vm._v("건"),
                                    ]),
                                    _c("div", { staticClass: "col-6" }, [
                                      _vm._v("생산1팀 "),
                                      _c("span", { staticClass: "safconbox" }, [
                                        _vm._v(_vm._s(_vm.safconData.team1)),
                                      ]),
                                      _vm._v("건"),
                                    ]),
                                    _c("div", { staticClass: "col-6" }, [
                                      _vm._v("생산2팀 "),
                                      _c("span", { staticClass: "safconbox" }, [
                                        _vm._v(_vm._s(_vm.safconData.team2)),
                                      ]),
                                      _vm._v("건"),
                                    ]),
                                    _c("div", { staticClass: "col-6" }, [
                                      _vm._v("생산3팀 "),
                                      _c("span", { staticClass: "safconbox" }, [
                                        _vm._v(_vm._s(_vm.safconData.team3)),
                                      ]),
                                      _vm._v("건"),
                                    ]),
                                    _c("div", { staticClass: "col-6" }, [
                                      _vm._v("기    타 "),
                                      _c("span", { staticClass: "safconbox" }, [
                                        _vm._v(_vm._s(_vm.safconData.etc)),
                                      ]),
                                      _vm._v("건"),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-12 no-padding" }, [
              _c("div", { staticClass: "cardcontents" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(" " + _vm._s(_vm.$label("LBL0000941")) + " "),
                  _c(
                    "div",
                    { staticClass: "card-more main-header-input" },
                    [
                      _c("c-plant", {
                        attrs: {
                          type: "search",
                          name: "plantCd3",
                          isFirstValue: false,
                          label: "",
                        },
                        on: { datachange: _vm.getImprProgress },
                        model: {
                          value: _vm.plantCd3,
                          callback: function ($$v) {
                            _vm.plantCd3 = $$v
                          },
                          expression: "plantCd3",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "card-body nopadgrid" },
                  [
                    _c("c-table", {
                      ref: "grid2",
                      attrs: {
                        tableId: "grid2",
                        isTitle: false,
                        isDashboard: true,
                        columnSetting: false,
                        usePaging: false,
                        filtering: false,
                        isFullScreen: false,
                        hideBottom: true,
                        gridHeight: "217px",
                        columns: _vm.grid2.columns,
                        data: _vm.grid2.data,
                      },
                      on: { linkClick: _vm.linkClickImp },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c("div", { staticClass: "cardcontents directorMainLayer" }, [
              _c(
                "div",
                { staticClass: "card-header non-label-icon mapheader" },
                [
                  _c(
                    "q-chip",
                    {
                      staticClass: "chip-task",
                      attrs: {
                        square: "",
                        clickable: "",
                        outline: _vm.mapObj.activeTask !== 1,
                        color:
                          _vm.mapObj.activeTask === 1 ? "green-6" : "gray-4",
                        "text-color":
                          _vm.mapObj.activeTask === 1 ? "white" : "white",
                        icon: "directions",
                      },
                      on: { click: () => (_vm.mapObj.activeTask = 1) },
                    },
                    [_vm._v(" " + _vm._s(_vm.$label("LBL0000214")) + " ")]
                  ),
                  _c(
                    "q-chip",
                    {
                      staticClass: "chip-task",
                      attrs: {
                        square: "",
                        clickable: "",
                        outline: _vm.mapObj.activeTask !== 2,
                        color:
                          _vm.mapObj.activeTask === 2 ? "amber-7" : "gray-4",
                        "text-color":
                          _vm.mapObj.activeTask === 2 ? "white" : "white",
                        icon: "directions",
                      },
                      on: { click: () => (_vm.mapObj.activeTask = 2) },
                    },
                    [_vm._v(" " + _vm._s(_vm.$label("LBL0000334")) + " ")]
                  ),
                  _c(
                    "q-chip",
                    {
                      staticClass: "chip-task",
                      attrs: {
                        square: "",
                        clickable: "",
                        outline: _vm.mapObj.activeTask !== 4,
                        color:
                          _vm.mapObj.activeTask === 4
                            ? "deep-orange-6"
                            : "gray-4",
                        "text-color":
                          _vm.mapObj.activeTask === 4 ? "white" : "white",
                        icon: "directions",
                      },
                      on: { click: () => (_vm.mapObj.activeTask = 4) },
                    },
                    [_vm._v(" 안전사고 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-more main-header-input main-header-input2",
                    },
                    [
                      _c("c-datepicker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.mapObj.activeTask === 1,
                            expression: "mapObj.activeTask===1",
                          },
                        ],
                        attrs: {
                          range: true,
                          appendToBody: false,
                          name: "workPermitPeriod",
                        },
                        on: { datachange: _vm.getMapData },
                        model: {
                          value: _vm.mapObj.taskParam.workPermitPeriod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mapObj.taskParam,
                              "workPermitPeriod",
                              $$v
                            )
                          },
                          expression: "mapObj.taskParam.workPermitPeriod",
                        },
                      }),
                      _c("c-datepicker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.mapObj.activeTask === 2,
                            expression: "mapObj.activeTask===2",
                          },
                        ],
                        attrs: {
                          range: true,
                          appendToBody: false,
                          name: "assessRiskPeriod",
                        },
                        on: { datachange: _vm.getMapData },
                        model: {
                          value: _vm.mapObj.taskParam.assessRiskPeriod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mapObj.taskParam,
                              "assessRiskPeriod",
                              $$v
                            )
                          },
                          expression: "mapObj.taskParam.assessRiskPeriod",
                        },
                      }),
                      _c("c-datepicker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.mapObj.activeTask === 4,
                            expression: "mapObj.activeTask===4",
                          },
                        ],
                        attrs: {
                          range: true,
                          appendToBody: false,
                          name: "accidentPeriod",
                        },
                        on: { datachange: _vm.getMapData },
                        model: {
                          value: _vm.mapObj.taskParam.accidentPeriod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mapObj.taskParam,
                              "accidentPeriod",
                              $$v
                            )
                          },
                          expression: "mapObj.taskParam.accidentPeriod",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      staticClass: "mainmapstart",
                      attrs: {
                        outline: !_vm.mapObj.interval.isPause,
                        color: _vm.mapObj.interval.isPause ? "green-6" : "red",
                        "text-color": _vm.mapObj.interval.isPause
                          ? "white"
                          : "red",
                        size: "9px",
                        label: _vm.mapObj.interval.isPause
                          ? _vm.$comm.getLangLabel("LBL0000338")
                          : _vm.$comm.getLangLabel("LBL0000339"),
                        icon: _vm.mapObj.interval.isPause
                          ? "restart_alt"
                          : "pause",
                      },
                      on: { click: _vm.pause },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$label("LBL0000337"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  ref: "carousel",
                  staticClass: "card-body",
                  staticStyle: { padding: "15px !important" },
                },
                [
                  _vm.mapObj.maps && _vm.mapObj.maps.length > 0
                    ? _c(
                        "q-carousel",
                        {
                          staticClass: "workPermit-carousel",
                          attrs: {
                            swipeable: "",
                            animated: "",
                            arrows: "",
                            "control-type": "regular",
                            "control-color": "grey-6",
                            infinite: "",
                          },
                          model: {
                            value: _vm.mapObj.slide,
                            callback: function ($$v) {
                              _vm.$set(_vm.mapObj, "slide", $$v)
                            },
                            expression: "mapObj.slide",
                          },
                        },
                        _vm._l(_vm.mapObj.maps, function (map, idx) {
                          return _c(
                            "q-carousel-slide",
                            {
                              key: idx,
                              attrs: { name: idx, "img-src": map.mapSrc },
                            },
                            [
                              [
                                _c("div", {
                                  staticClass: "mapTitleDiv",
                                  domProps: {
                                    textContent: _vm._s(map.mapName),
                                  },
                                }),
                                _vm.mapObj.activeTask === 1
                                  ? _vm._l(map.maps, function (item, idx) {
                                      return _c(
                                        "VueDraggableResizable",
                                        {
                                          key: idx,
                                          ref: "markImage",
                                          refInFor: true,
                                          staticClass: "mainMarkImage",
                                          attrs: {
                                            resizable: false,
                                            parent: true,
                                            draggable: false,
                                            x:
                                              item.locationXcoordinate *
                                              _vm.mapRate,
                                            y:
                                              item.locationYcoordinate *
                                              _vm.mapRate,
                                            w: 40,
                                            h: 40,
                                            grid: [20, 20],
                                          },
                                        },
                                        [
                                          _c("q-icon", {
                                            staticClass: "blinking",
                                            attrs: { name: "push_pin" },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "markImage-abbreviation-parent",
                                              style: {
                                                width: "341px",
                                                left: _vm.getLeft(item),
                                                top: _vm.getTop(item),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "markImage-abbreviation",
                                                },
                                                [
                                                  _c(
                                                    "transition",
                                                    {
                                                      attrs: {
                                                        name: "mark-list",
                                                        tag: "div",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: true,
                                                              expression:
                                                                "true",
                                                            },
                                                          ],
                                                        },
                                                        _vm._l(
                                                          item.resultTypes,
                                                          function (
                                                            resultType,
                                                            idx
                                                          ) {
                                                            return _c(
                                                              "q-banner",
                                                              {
                                                                key: idx,
                                                                class: [
                                                                  idx % 2 === 1
                                                                    ? "bg-grey-3"
                                                                    : "bg-grey-1",
                                                                  "markImage-abbreviation-banner-detail",
                                                                ],
                                                                attrs: {
                                                                  dense: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "avatar",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "q-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "text-red",
                                                                                attrs:
                                                                                  {
                                                                                    name: _vm.workPermitIconName(
                                                                                      resultType
                                                                                    ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      resultType.sopWorkTypeName
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("q-badge", {
                                                                  attrs: {
                                                                    color:
                                                                      _vm.workPermitbadgeColor(
                                                                        resultType.swpStepName
                                                                      ),
                                                                    label:
                                                                      resultType.swpStepName,
                                                                  },
                                                                }),
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "q-ml-sm",
                                                                  attrs: {
                                                                    outline: "",
                                                                    round: "",
                                                                    dense: "",
                                                                    color:
                                                                      "red",
                                                                    size: "9px",
                                                                    label: "",
                                                                    icon: "add",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openWorkPermit(
                                                                          resultType
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "caption-work",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          resultType.workSummary
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    })
                                  : _vm._e(),
                                _vm._l(map.otherMaps, function (mp, idx) {
                                  return _c(
                                    "VueDraggableResizable",
                                    {
                                      key: "other" + idx,
                                      ref: "processMark" + mp.processCd,
                                      refInFor: true,
                                      staticClass: "my-class",
                                      class:
                                        mp.processCd ===
                                        _vm.mapObj.process.processCd
                                          ? "my-active-class"
                                          : "",
                                      staticStyle: { "z-index": "1" },
                                      attrs: {
                                        parent: true,
                                        draggable: false,
                                        resizable: false,
                                        x: mp.x * _vm.mapRate,
                                        y: mp.y * _vm.mapRate,
                                        w: mp.w * _vm.mapRate,
                                        h: mp.h * _vm.mapRate,
                                        grid: [20, 20],
                                      },
                                      on: {
                                        activated: function ($event) {
                                          return _vm.onClickProcess(mp)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(mp.processName)),
                                      ]),
                                    ]
                                  )
                                }),
                                _vm._l(
                                  map.otherMaps.filter((i) => {
                                    return (
                                      i.tasks.filter((i) => {
                                        return (
                                          i.taskType ===
                                          String(_vm.mapObj.activeTask)
                                        )
                                      }).length > 0
                                    )
                                  }),
                                  function (mp, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: "other-tran" + idx,
                                        staticClass: "task-parent",
                                        style: _vm.setStyleTaskArea(mp),
                                        on: {
                                          mouseover: () => {
                                            mp.isHover = true
                                          },
                                          mouseleave: () => {
                                            mp.isHover = false
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.mapObj.activeTask === 2 &&
                                                  mp.tasks.filter((i) => {
                                                    return (
                                                      i.taskType ===
                                                      String(
                                                        _vm.mapObj.activeTask
                                                      )
                                                    )
                                                  }).length > 0,
                                                expression:
                                                  "mapObj.activeTask===2 \n                      && mp.tasks.filter(i => {\n                          return i.taskType === String(mapObj.activeTask)\n                        }).length > 0",
                                              },
                                            ],
                                            staticClass:
                                              "rounded-borders task-detail",
                                            staticStyle: {
                                              "border-top": "3px solid #ffb300",
                                            },
                                          },
                                          _vm._l(
                                            mp.tasks
                                              .filter((i) => {
                                                return (
                                                  i.taskType ===
                                                  String(_vm.mapObj.activeTask)
                                                )
                                              })
                                              .slice(0, 1),
                                            function (task, _idx) {
                                              return _c(
                                                "q-banner",
                                                {
                                                  key: _idx,
                                                  attrs: { dense: "" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mainmapbannerTitle",
                                                    },
                                                    [_vm._v(_vm._s(task.name))]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption-work2",
                                                      staticStyle: {
                                                        "text-align": "left",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$label(
                                                              "LBL0000340"
                                                            )
                                                          ) +
                                                          ": "
                                                      ),
                                                      _c(
                                                        "font",
                                                        {
                                                          staticClass:
                                                            "text-amber-7",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              task.actionCnt
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$label(
                                                              "LBL0000341"
                                                            )
                                                          ) +
                                                          ": "
                                                      ),
                                                      _c(
                                                        "font",
                                                        {
                                                          staticClass:
                                                            "text-amber-7",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              task.riskRegisterCnt
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$label(
                                                              "LBL0000342"
                                                            )
                                                          ) +
                                                          ": "
                                                      ),
                                                      _c(
                                                        "font",
                                                        {
                                                          staticClass:
                                                            "text-amber-7",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              task.notActionImprCnt
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.mapObj.activeTask === 4 &&
                                                  mp.tasks.filter((i) => {
                                                    return (
                                                      i.taskType ===
                                                      String(
                                                        _vm.mapObj.activeTask
                                                      )
                                                    )
                                                  }).length > 0,
                                                expression:
                                                  "mapObj.activeTask===4\n                      && mp.tasks.filter(i => {\n                          return i.taskType === String(mapObj.activeTask)\n                        }).length > 0",
                                              },
                                            ],
                                            staticClass:
                                              "rounded-borders task-detail",
                                            staticStyle: {
                                              "border-top": "3px solid #ff5722",
                                            },
                                          },
                                          _vm._l(
                                            mp.tasks
                                              .filter((i) => {
                                                return (
                                                  i.taskType ===
                                                  String(_vm.mapObj.activeTask)
                                                )
                                              })
                                              .slice(0, 1),
                                            function (task, _idx) {
                                              return _c(
                                                "q-banner",
                                                {
                                                  key: _idx,
                                                  attrs: { dense: "" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mainmapbannerTitle",
                                                    },
                                                    [_vm._v(_vm._s(task.name))]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption-work2",
                                                      staticStyle: {
                                                        "text-align": "left",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 재해유형: "),
                                                      _c(
                                                        "font",
                                                        {
                                                          staticClass:
                                                            "text-deep-orange-6",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              task.accidentTypeName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" 사고자: "),
                                                      _c(
                                                        "font",
                                                        {
                                                          staticClass:
                                                            "text-deep-orange-6",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              task.accidentUserName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" 소속: "),
                                                      _c(
                                                        "font",
                                                        {
                                                          staticClass:
                                                            "text-deep-orange-6",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              task.accidentDeptName +
                                                                "/" +
                                                                task.accidentSpotName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" 생년월일: "),
                                                      _c(
                                                        "font",
                                                        {
                                                          staticClass:
                                                            "text-deep-orange-6",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              task.accidentBirthDate
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                            ],
                            2
                          )
                        }),
                        1
                      )
                    : _c("el-empty", {
                        attrs: {
                          "image-size": 435,
                          description: _vm.$comm.getLangMessage("MSG0000137"),
                        },
                      }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 아차사고 현황 "),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-plant", {
                      attrs: {
                        type: "search",
                        name: "plantCdAcc",
                        isFirstValue: false,
                        label: "",
                      },
                      on: { datachange: _vm.getNearmiss },
                      model: {
                        value: _vm.plantCdAcc,
                        callback: function ($$v) {
                          _vm.plantCdAcc = $$v
                        },
                        expression: "plantCdAcc",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "gridAcc",
                    attrs: {
                      tableId: "gridAcc",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "310px",
                      columns: _vm.gridAcc.columns,
                      data: _vm.gridAcc.data,
                    },
                    on: { linkClick: _vm.linkClickAcc },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" " + _vm._s(_vm.$label("LBL0000943")) + " "),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-plant", {
                      attrs: {
                        type: "search",
                        label: "",
                        isFirstValue: false,
                        name: "plantCd4",
                      },
                      on: { datachange: _vm.datachange4 },
                      model: {
                        value: _vm.plantCd4,
                        callback: function ($$v) {
                          _vm.plantCd4 = $$v
                        },
                        expression: "plantCd4",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-more main-header-input main-header-input2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        type: "year",
                        default: "today",
                        name: "actionCompleteRequestDate",
                      },
                      on: { input: _vm.getImprStatus },
                      model: {
                        value: _vm.actionCompleteRequestDate,
                        callback: function ($$v) {
                          _vm.actionCompleteRequestDate = $$v
                        },
                        expression: "actionCompleteRequestDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("apexchart", {
                    ref: "imprChart",
                    attrs: {
                      height: "300px",
                      type: "bar",
                      width: _vm.imprChart.chartWidth,
                      options: _vm.imprChart.chartOptions,
                      series: _vm.imprChart.series,
                    },
                    on: { dataPointSelection: _vm.selectedBarImp },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-notice-popup"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }